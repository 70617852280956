z
<template>
    <footer class="ll-footer">
        <div class="ll-footer__content container">
            <div class="ll-footer__info">
                <img
                    class="ll-footer__info-logo"
                    src="@/assets/icons/logo.svg"
                    alt="logo" />

                <p class="ll-footer__info-text">
                    В LeadLogic мы воплощаем цифровые идеи в реальность. Наши инновационные IT-решения направлены на рост, повышение эффективности и развитие вашего бизнеса. Доверяя нам, вы получаете ключ к успеху в цифровом мире.
                </p>
            </div>

            <div class="ll-footer__links">
                <h2 class="ll-footer__links-title">Карта сайта:</h2>
                <a
                    class="ll-footer__link"
                    href="/about">
                    О нашей студии
                </a>
                <a
                    class="ll-footer__link"
                    href="/portfolio">
                    Проекты наших рук
                </a>
                <a
                    class="ll-footer__link"
                    href="/services">
                    На что мы способны
                </a>
                <a
                    class="ll-footer__link"
                    href="/career">
                    Уютная команда
                </a>
                <a
                    class="ll-footer__link"
                    href="/blog">
                    Новости о leadlogic
                </a>
                <a
                    class="ll-footer__link"
                    href="/contacts">
                    Связь с нами
                </a>
            </div>

            <div class="ll-footer__links">
                <h2 class="ll-footer__links-title">График работы:</h2>
                <p class="ll-footer__link">
                    Пон. - Пят.
                    <strong>09:00 - 18:00</strong>
                </p>
                <p class="ll-footer__link">
                    Суббота
                    <strong>10:00 - 17:00</strong>
                </p>
                <p class="ll-footer__link">
                    Воскресенье
                    <strong>12:00 - 17:00</strong>
                </p>
            </div>
            
            <div class="ll-footer__links">
                <h3 class="ll-footer__links-title">Связаться</h3>
                <a
                    href="#!"
                    class="ll-footer__link">
                    <img
                        class="ll-footer__link-icon"
                        src="@/assets/icons/icon-contact-address.svg"
                        alt="" />
                    Буюк ипак йули. дом 48, 29
                </a>
                <a
                    href="#!"
                    class="ll-footer__link">
                    <img
                        class="ll-footer__link-icon"
                        src="@/assets/icons/icon-contact-mail.svg"
                        alt="" />
                    info@leadlogic.uz
                </a>
                <a
                    href="#!"
                    class="ll-footer__link">
                    <img
                        class="ll-footer__link-icon"
                        src="@/assets/icons/icon-contact-phone.svg"
                        alt="" />
                    +998 (90) 091 15 59
                </a>
                <CompButton
                    :llbtnText="'Связаться с нами'"
                    :llbtnLink="'/contacts'" />
            </div>
        </div>
        <div class="ll-footer__rights container">
            <div class="ll-footer__rights-socials">
                <a
                    href="#!"
                    target="_blank"
                    class="ll-footer__rights-social">
                    <img
                        src="@/assets/icons/icon-contact-instagram.svg"
                        alt="instagram" />
                </a>
                <a
                    href="#!"
                    target="_blank"
                    class="ll-footer__rights-social">
                    <img
                        src="@/assets/icons/icon-contact-facebook.svg"
                        alt="facebook" />
                </a>
                <a
                    href="#!"
                    target="_blank"
                    class="ll-footer__rights-social">
                    <img
                        src="@/assets/icons/icon-contact-tiktok.svg"
                        alt="tiktok" />
                </a>
                <a
                    href="#!"
                    target="_blank"
                    class="ll-footer__rights-social">
                    <img
                        src="@/assets/icons/icon-contact-telegram.svg"
                        alt="telegram" />
                </a>
                <a
                    href="#!"
                    target="_blank"
                    class="ll-footer__rights-social">
                    <img
                        src="@/assets/icons/icon-contact-youtube.svg"
                        alt="youtube" />
                </a>
            </div>
            <p class="ll-footer__rights-text">
                (c) 2024 Leadlogic - Ваш надежный путеводитель в мир информационных технологий!.
                <br />
                Все права защищены.
            </p>
        </div>
    </footer>
</template>

<script>
import CompButton from "@/components/compButton.vue";

export default {
    name: "component-footer",
    components: {
        CompButton,
    },
};
</script>
