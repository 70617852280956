import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMask from "v-mask";

// import VueApi from "./plugins/axios";
// import store from "./store";
// import VuePortal from "portal-vue";

// components
import CompButton from "@/components/compButton.vue";
Vue.component("CompButton", CompButton);

// template
Vue.directive("scroll", {
    inserted: function (el, binding) {
        const delay = binding.value || "0.3s"; // Use the provided delay or default to '0s'
        el.style.animationDelay = delay;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    el.classList.add("ll-animated");
                    observer.unobserve(el);
                }
            },
            {threshold: 0.1},
        );

        observer.observe(el);
    },
});

import "@/assets/sass/common.scss";

Vue.config.productionTip = false;
Vue.use(VueMask);
// Vue.prototype.$api = VueApi;
// Vue.use(store);
// Vue.use(VuePortal);

new Vue({
    router,
    // store,
    render: (h) => h(App),
}).$mount("#app");
