var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"ll-footer"},[_c('div',{staticClass:"ll-footer__content container"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"ll-footer__links"},[_c('h3',{staticClass:"ll-footer__links-title"},[_vm._v("Связаться")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('CompButton',{attrs:{"llbtnText":'Связаться с нами',"llbtnLink":'/contacts'}})],1)]),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ll-footer__info"},[_c('img',{staticClass:"ll-footer__info-logo",attrs:{"src":require("@/assets/icons/logo.svg"),"alt":"logo"}}),_c('p',{staticClass:"ll-footer__info-text"},[_vm._v(" В LeadLogic мы воплощаем цифровые идеи в реальность. Наши инновационные IT-решения направлены на рост, повышение эффективности и развитие вашего бизнеса. Доверяя нам, вы получаете ключ к успеху в цифровом мире. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ll-footer__links"},[_c('h2',{staticClass:"ll-footer__links-title"},[_vm._v("Карта сайта:")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/about"}},[_vm._v(" О нашей студии ")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/portfolio"}},[_vm._v(" Проекты наших рук ")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/services"}},[_vm._v(" На что мы способны ")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/career"}},[_vm._v(" Уютная команда ")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/blog"}},[_vm._v(" Новости о leadlogic ")]),_c('a',{staticClass:"ll-footer__link",attrs:{"href":"/contacts"}},[_vm._v(" Связь с нами ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ll-footer__links"},[_c('h2',{staticClass:"ll-footer__links-title"},[_vm._v("График работы:")]),_c('p',{staticClass:"ll-footer__link"},[_vm._v(" Пон. - Пят. "),_c('strong',[_vm._v("09:00 - 18:00")])]),_c('p',{staticClass:"ll-footer__link"},[_vm._v(" Суббота "),_c('strong',[_vm._v("10:00 - 17:00")])]),_c('p',{staticClass:"ll-footer__link"},[_vm._v(" Воскресенье "),_c('strong',[_vm._v("12:00 - 17:00")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ll-footer__link",attrs:{"href":"#!"}},[_c('img',{staticClass:"ll-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-contact-address.svg"),"alt":""}}),_vm._v(" Буюк ипак йули. дом 48, 29 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ll-footer__link",attrs:{"href":"#!"}},[_c('img',{staticClass:"ll-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-contact-mail.svg"),"alt":""}}),_vm._v(" info@leadlogic.uz ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"ll-footer__link",attrs:{"href":"#!"}},[_c('img',{staticClass:"ll-footer__link-icon",attrs:{"src":require("@/assets/icons/icon-contact-phone.svg"),"alt":""}}),_vm._v(" +998 (90) 091 15 59 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ll-footer__rights container"},[_c('div',{staticClass:"ll-footer__rights-socials"},[_c('a',{staticClass:"ll-footer__rights-social",attrs:{"href":"#!","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-contact-instagram.svg"),"alt":"instagram"}})]),_c('a',{staticClass:"ll-footer__rights-social",attrs:{"href":"#!","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-contact-facebook.svg"),"alt":"facebook"}})]),_c('a',{staticClass:"ll-footer__rights-social",attrs:{"href":"#!","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-contact-tiktok.svg"),"alt":"tiktok"}})]),_c('a',{staticClass:"ll-footer__rights-social",attrs:{"href":"#!","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-contact-telegram.svg"),"alt":"telegram"}})]),_c('a',{staticClass:"ll-footer__rights-social",attrs:{"href":"#!","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/icons/icon-contact-youtube.svg"),"alt":"youtube"}})])]),_c('p',{staticClass:"ll-footer__rights-text"},[_vm._v(" (c) 2024 Leadlogic - Ваш надежный путеводитель в мир информационных технологий!. "),_c('br'),_vm._v(" Все права защищены. ")])])
}]

export { render, staticRenderFns }